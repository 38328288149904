/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.5.1/dist/jquery.min.js
 * - /npm/popper.js@1.15.0/dist/umd/popper.min.js
 * - /npm/bootstrap@4.5.3/dist/js/bootstrap.min.js
 * - /npm/svg-injector@1.1.3/dist/svg-injector.min.js
 * - /npm/in-view@0.6.1/dist/in-view.min.js
 * - /npm/sticky-kit@1.1.2/dist/sticky-kit.min.js
 * - /npm/imagesloaded@4.1.4/imagesloaded.pkgd.min.js
 * - /npm/select2@4.0.13/dist/js/select2.min.js
 * - /npm/noty@3.2.0-beta-deprecated/lib/noty.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
